import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { colors, font, desktopBreakpoint, headerHeight } from '../styles'

// Utils
import { getPathname } from '../utils'

// Hooks
import { useLayoutCtx, useDictionaryCtx } from '../hooks/context'

// Components
import SEO from '../components/shared/SEO'
import Image from 'gatsby-image'
import RichText from '../components/shared/RichText'
import ArrowFlat from '../components/graphics/ArrowFlat'

const StyledText = styled.section`
  overflow: hidden;
  padding-top: ${headerHeight.mobile}px;
  font-family: ${font.larsseit};
  position: relative;

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${headerHeight.desktop + 55}px;
    display: flex;
    overflow: inherit;
    max-width: 1280px;
    margin: auto;
  }
`

const MenuBox = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    flex: 0 0 28.33%;
    margin: 0 16px 60px 55px;
    height: fit-content;
    max-height: 80vh;
    position: sticky;
    position: -webkit-sticky;
    top: 127px;
    overflow: scroll;
    border-radius: 6px;
  }
`

const MenuWrapper = styled.div`
  background-color: rgba(238, 227, 218, 0.25);
  display: ${props => (props.show ? 'block' : 'none')};
  padding: 29px 10px 0;
  margin: 60px 16px;
  border-radius: 6px;

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: flex-start;
    border-radius: 0;
    flex-direction: column;
    display: flex;
    padding: 45px 20px 0;
    margin: 0;
    height: fit-content;
  }
`

const Title = styled.h1`
  font-size: 36px;
  line-height: 39px;
  font-family: ${font.ben};
  text-transform: lowercase;
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
  }
`

const Menu = styled.div`
  font-family: ${font.ben};
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  flex-direction: column;
  text-transform: lowercase;

  a {
    white-space: nowrap;

    @media (min-width: ${desktopBreakpoint}) {
      padding-right: 0;
    }
  }

  > a:not(:last-child) {
    border-bottom: solid 1px #e7e7e7;
  }

  svg {
    transform: rotate(275deg);
    path {
      stroke: #c8c8c8;
    }
  }

  [aria-current] {
    svg {
      path {
        stroke: ${colors.brownDark};
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 21px;
    line-height: 47px;
  }
`

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
`

const ContentWrapper = styled.div`
  display: flex;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 32px;
    margin-top: 30px;
  }
}
`

const StyledImage = styled(Image)`
  border-radius: 15px;
  flex-grow: 1;
  overflow: hidden;
  width: 280px;
  @media (min-width: ${desktopBreakpoint}) {
    height: 100%;
    margin-top: 0;
    border-radius: 28px;
  }
`

const RichTextWrapper = styled.div`
  margin-top: 24px;
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
  }
`

const Wrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  margin-top: 60px;
  @media (min-width: ${desktopBreakpoint}) {
    position: relative;
    display: block;
    min-height: 100vh;
    margin-top: 0;
    h1 {
      display: none;
    }
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${colors.brownDarkest};
  margin: 0 13px 60px 19px;
  padding: 25px 18px 71px 27px;
  background-color: rgba(238, 227, 218, 0.25);
  border-radius: 6px;

  p {
    display: block;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  u {
    text-decoration: underline;
    color: ${colors.brownLight};
  }

  a {
    text-decoration: underline;
    color: ${colors.orange};
  }

  p,
  h2,
  h1 {
    display: block;
  }

  h1 {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: 36px;
    line-height: 39px;
    margin-bottom: 36px;
  }

  h2 {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 12px;
  }

  li {
    margin-bottom: 24px;
  }

  ul {
    list-style-type: disc;
    margin: 0 20px 24px;

    p {
      margin-bottom: 0;
    }

    @media (min-width: ${desktopBreakpoint}) {
      margin: 0 30px 24px;
    }
  }

  img {
    width: 100%;
  }

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex: 0 0 66.66%;
    padding: 92px 53px 86px 73px;
    margin: 0 56px 54px 0;
    position: relative;
  }
`
const NoImage = styled(Content)``

const ArrowLink = styled(Link)`
  font-family: ${font.ben};
  font-size: 18px;
  line-height: 21px;
  color: #c8c8c8;
  text-transform: lowercase;
  position: absolute;
  top: 98px;
  left: 17px;
  svg {
    margin-right: 9px;
    transform: rotate(90deg);
    path {
      stroke: #c8c8c8;
    }
  }
  span {
    display: inline-block;
    font-weight: 600;
    &::after {
      content: '';
      display: block;
      position: relative;
      bottom: -0;
      height: 1px;
      background: #c8c8c8;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const LangWrapper = styled.div`
  // display: flex;
  display: none;
  justify-content: flex-end;
  margin: auto 13px 5px auto;

  a:last-child {
    margin-left: 13px;
    position: relative;
    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 1px;
      background: black;
      position: absolute;
      top: 0;
      left: -7px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    top: 28px;
    right: 84px;
  }
`

const LangButton = styled(Link)``

const Text = ({
  data: {
    contentfulTextPage: { slug, title, text, seoDescription, seoTitle, image },
    allContentfulTextPage: { edges },
  },
}) => {
  const { node_locale, textPageMenu } = useLayoutCtx()
  const { goBack } = useDictionaryCtx()

  const isSupportPage = slug == 'support'

  if (isSupportPage) {
    text = edges[0].node.text
    image = edges[0].node.image
  }

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} slug={slug} isPage />
      <StyledText>
        <MenuBox>
          <MenuWrapper show={isSupportPage}>
            <Title>SUPPORT</Title>
            <Menu>
              {edges.map(
                (data, i) =>
                  data.node.slug !== 'support' && (
                    <MenuItem
                      key={i}
                      to={getPathname(node_locale, data.node.slug)}
                    >
                      {data.node.title}
                      <ArrowFlat />
                    </MenuItem>
                  ),
              )}
            </Menu>
          </MenuWrapper>
        </MenuBox>
        <Wrapper show={!isSupportPage}>
          <ArrowLink to={getPathname(node_locale, 'support')}>
            <ArrowFlat /> <span>{goBack}</span>
          </ArrowLink>
          <Title>{title}</Title>
          {text && image && (
            <Content>
              <ContentWrapper>
                <StyledImage fluid={image.fluid} alt={image.title} />
              </ContentWrapper>
              <RichTextWrapper>
                <RichText json={text.json} />
              </RichTextWrapper>
            </Content>
          )}

          {text && !image && (
            <NoImage>
              <RichTextWrapper>
                <RichText json={text.json} />
              </RichTextWrapper>
            </NoImage>
          )}
        </Wrapper>
      </StyledText>
    </>
  )
}

export const PageQuery = graphql`
  query Text($id: String!, $node_locale: String!) {
    contentfulTextPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      slug
      title
      text {
        json
      }
      image {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    allContentfulTextPage(
      filter: { node_locale: { eq: $node_locale } }
      sort: { fields: title }
    ) {
      edges {
        node {
          id
          slug
          title
          text {
            json
          }
        }
      }
    }
  }
`

export default Text
